import React, { useContext, useEffect, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "popmotion"
import useInterval from "../../../hooks/useInterval"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { toUsd, vehicleCategory } from "../../../helpers"
import { LocationContext } from "../../../contexts/Location"
import { HeaderContext } from "../../../contexts/Header"
import { NewCarouselProps } from "../../NewCarousel"

/**
 * Carousel component with variants for each carousel style across the codebase. New behavior can be passed inside the variants prop.
 *
 * @author Tyler
 * @param {Object[]} slides - An array of objects containing the content of each slide
 * @param {boolean} continuous - Whether the carousel should loop back to the first slide when the last slide is reached
 * @param {boolean} hero - Whether the carousel should be a hero carousel
 * @param {boolean} centered - Whether the carousel should be centered
 * @param {number} activeSlide - The index of the slide that is currently active
 * @param {string} linkUrl - Optional link url. When provided, slides will be a link to this url.
 * @param {Dispatch<React.SetStateAction<number>>} setActiveSlide - A function that sets the active slide index
 * @returns {JSX.Element} - A carousel of slides
 */

const NewInventoryCarousel = ({
  id,
  slides,
  continuous = false,
  hero = false,
  inventory = false,
  inventoryData,
  centered,
  mobileButtons,
  activeSlide,
  linkUrl,
  setActiveSlide,
  children,
  section,
  chevronPosition = "inside",
  tealIncludeLabel,
  location,
  imageType,
  vehiclePosition,
  ...remainingProps
}: NewCarouselProps): JSX.Element => {
  const [[slide, direction], setSlide] = useState([0, 0])

  const [isHovered, setIsHovered] = useState(false)
  const { toggleMenu } = useContext(HeaderContext)

  const timeout = 8000

  //adding this to prevent the content from shifting when the menu is open and the carousel changes slides
  useEffect(() => {
    if (toggleMenu) {
      setIsHovered(true)
    } else {
      setIsHovered(false)
    }
  }, [toggleMenu])

  const variants = {
    enter: (direction: number) => {
      return {
        x: inventory ? 0 : direction > 0 ? 1000 : -1000,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: inventory ? 0 : direction < 0 ? 1000 : -1000,
        opacity: 0,
      }
    },
  }

  const slideIndex =
    continuous && slides?.length > 1 ? wrap(0, slides.length, slide) : slide

  const changeSlide = (direction: number) => {
    setSlide([slide + direction, direction])
  }

  const swipeConfidenceThreshold = 10000

  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity
  }

  useInterval(
    () => {
      if (hero && slides.length > 1) changeSlide(1)
    },
    timeout,
    isHovered,
  )

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const [{ dealers }] = useContext(LocationContext)

  // Inventory type
  if (inventory)
    return (
      <>
        <section
          id={id}
          css={[tw`relative max-w-7xl mx-auto`]}
          {...remainingProps}
        >
          <div css={[tw`overflow-hidden`]}>
            {slides?.length > 1 ? (
              <AnimatePresence initial={false} custom={direction}>
                <motion.div
                  key={slide}
                  custom={direction}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    x: { type: "spring", stiffness: 200, damping: 30 },
                    opacity: { duration: 0 },
                  }}
                  drag="x"
                  dragConstraints={{ left: 0, right: 0 }}
                  dragElastic={0}
                  onDragEnd={(e, { offset, velocity }) => {
                    const swipe = swipePower(offset.x, velocity.x)

                    if (swipe < -swipeConfidenceThreshold) {
                      changeSlide(1),
                        trackTealEvent({
                          tealium_event: "carousel_click",
                          carousel_action: "vehicle|right drag",
                          vehicle_model: inventoryData?.series,
                          vehicle_exterior_color:
                            inventoryData?.color?.exterior.name,
                          vehicle_year: inventoryData?.year,
                          vehicle_interior_color:
                            inventoryData?.color?.interior.name,
                          vehicle_trim: inventoryData?.grade,
                          vehicle_trim_id: inventoryData?.modelNumber,
                          vehicle_vin: inventoryData?.vin,
                          allocated_dealer_name: dealers.filter(
                            dealer =>
                              dealer?.DealerCode == inventoryData?.dealer,
                          )[0]?.Name,
                          allocated_dealer_code: inventoryData?.dealer,
                          vehicle_sale_price: `$${toUsd(inventoryData?.msrp)}`,
                          vehicle_model_truncated: inventoryData?.series,
                          vehicle_segment_vehicle_page: vehicleCategory(
                            inventoryData?.series,
                          ),
                          has_port_photos:
                            slides?.length > 4 ? "true" : "false", // if we have more than 4 images then we have port photos
                        })
                    } else if (swipe > swipeConfidenceThreshold) {
                      changeSlide(-1),
                        trackTealEvent({
                          tealium_event: "carousel_click",
                          carousel_action: "vehicle|left drag",
                          vehicle_model: inventoryData?.series,
                          vehicle_exterior_color:
                            inventoryData?.color?.exterior.name,
                          vehicle_year: inventoryData?.year,
                          vehicle_interior_color:
                            inventoryData?.color?.interior.name,
                          vehicle_trim: inventoryData?.grade,
                          vehicle_trim_id: inventoryData?.modelNumber,
                          vehicle_vin: inventoryData?.vin,
                          allocated_dealer_name: dealers.filter(
                            dealer =>
                              dealer?.DealerCode == inventoryData?.dealer,
                          )[0]?.Name,
                          allocated_dealer_code: inventoryData?.dealer,
                          vehicle_sale_price: `$${toUsd(inventoryData?.msrp)}`,
                          vehicle_model_truncated: inventoryData?.series,
                          vehicle_segment_vehicle_page: vehicleCategory(
                            inventoryData?.series,
                          ),
                          has_port_photos:
                            slides?.length > 4 ? "true" : "false", // if we have more than 4 images then we have port photos
                        })
                    }
                  }}
                  onMouseOver={() => setIsHovered(true)}
                  onMouseOut={() => setIsHovered(false)}
                  css={[tw`absolute top-0 left-0 w-full h-auto`]}
                >
                  {children({ slideIndex })}
                </motion.div>
              </AnimatePresence>
            ) : (
              <>{children({ slideIndex })}</>
            )}
          </div>

          {slideIndex > 0 && (
            <button
              onClick={() => {
                changeSlide(-1)
                trackTealEvent({
                  tealium_event: "carousel_click",
                  carousel_action:
                    location !== "inventory"
                      ? `${location}:left arrow`
                      : "left arrow",
                  vehicle_model: inventoryData?.series,
                  vehicle_exterior_color: inventoryData?.color?.exterior.name,
                  vehicle_year: inventoryData?.year,
                  vehicle_interior_color: inventoryData?.color?.interior.name,
                  vehicle_trim: inventoryData?.grade,
                  vehicle_trim_id: inventoryData?.modelNumber,
                  vehicle_vin: inventoryData?.vin,
                  allocated_dealer_name: dealers.filter(
                    dealer => dealer?.DealerCode == inventoryData?.dealer,
                  )[0]?.Name,
                  allocated_dealer_code: inventoryData?.dealer,
                  vehicle_sale_price: `$${toUsd(inventoryData?.msrp)}`,
                  vehicle_model_truncated: inventoryData?.series,
                  vehicle_segment_vehicle_page: vehicleCategory(
                    inventoryData?.series,
                  ),
                  has_port_photos: slides?.length > 4 ? "true" : "false", // if we have more than 4 images then we have port photos
                })
              }}
              css={[
                tw`opacity-100 absolute left-4 top-1/2 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full transition-all hover:(bg-gray-400 opacity-100) focus-visible:(bg-gray-400 opacity-100)`,
                tw`lg:(opacity-0)`,
                isHovered && tw`lg:(opacity-100 duration-150 transition-all)`,
                chevronPosition === "outside" && tw`lg:(-left-20)`,
              ]}
              disabled={slideIndex == 0}
              analytics-id={`left arrow ${imageType}:${location}:${
                vehiclePosition + 1
              }`}
            >
              <Icon.Chevron
                direction="left"
                color="black"
                css={[tw`h-5 lg:(hidden)`]}
              />
              <Icon.Chevron
                direction="left"
                color="black"
                css={[tw`h-5 hidden lg:(block)`]}
              />
            </button>
          )}
          {slideIndex < slides?.length - 1 && (
            <button
              onClick={() => {
                changeSlide(1)
                trackTealEvent({
                  tealium_event: "carousel_click",
                  carousel_action:
                    location !== "inventory"
                      ? `${location}:right arrow`
                      : "right arrow",
                  vehicle_model: inventoryData?.series,
                  vehicle_exterior_color: inventoryData?.color?.exterior.name,
                  vehicle_year: inventoryData?.year,
                  vehicle_interior_color: inventoryData?.color?.interior.name,
                  vehicle_trim: inventoryData?.grade,
                  vehicle_trim_id: inventoryData?.modelNumber,
                  vehicle_vin: inventoryData?.vin,
                  allocated_dealer_name: dealers.filter(
                    dealer => dealer?.DealerCode == inventoryData?.dealer,
                  )[0]?.Name,
                  allocated_dealer_code: inventoryData?.dealer,
                  vehicle_sale_price: `$${toUsd(inventoryData?.msrp)}`,
                  vehicle_model_truncated: inventoryData?.series,
                  vehicle_segment_vehicle_page: vehicleCategory(
                    inventoryData?.series,
                  ),
                  has_port_photos: slides?.length > 4 ? "true" : "false", // if we have more than 4 images then we have port photos
                })
              }}
              css={[
                tw`opacity-100 absolute right-4 top-1/2 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full transition-all hover:(bg-gray-400 opacity-100) focus-visible:(bg-gray-400 opacity-100)`,
                tw`lg:(opacity-0)`,
                isHovered && tw`lg:(opacity-100 duration-150 transition-all)`,
                chevronPosition === "outside" && tw`lg:(-right-20)`,
              ]}
              className="button"
              disabled={slideIndex == slides.length - 1}
              analytics-id={`right arrow ${imageType}:${location}:${
                vehiclePosition + 1
              }`}
            >
              <Icon.Chevron
                direction="right"
                color="black"
                css={[tw`h-5 lg:(hidden)`]}
              />
              <Icon.Chevron
                direction="right"
                color="black"
                css={[tw`h-5 hidden lg:(block)`]}
              />
            </button>
          )}
        </section>
      </>
    )
}

export default NewInventoryCarousel
